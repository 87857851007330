import React from "react";
import { Col, Grid, Kbd, TextInput } from "@mantine/core";
import Icon from "@mdi/react";
import { mdiMagnify } from "@mdi/js";

import { useSelector } from "react-redux";
import BookInfo from "../../components/BookInfo";
import Template from "../templates/default";

const BookList = () => {
  const books = useSelector((state) => state.books);

  return (
    <Grid>
      {books.map((book, index) => (
        <Col span={12} sm={4} lg={3} key={index}>
          <BookInfo
            name={book.name_lk ? book.name_lk : book.name_en}
            slug={book.slug}
            author={book.author}
            language={book.language}
            categories={book.categories}
          />
        </Col>
      ))}
    </Grid>
  );
};

const SearchBar = () => {
  const rightSection = (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Kbd>Ctrl</Kbd>
      <span style={{ margin: "0 5px" }}>+</span>
      <Kbd>K</Kbd>
    </div>
  );

  return (
    <TextInput
      placeholder="Search"
      icon={<Icon path={mdiMagnify} size={1} />}
      rightSectionWidth={90}
      rightSection={rightSection}
      styles={{ rightSection: { pointerEvents: "none" } }}
    />
  );
};

function Browse() {
  return (
    <Template>
      <Grid>
        <Col span={12}>
          <SearchBar />
        </Col>
        <Col span={12}>{<BookList />}</Col>
      </Grid>
    </Template>
  );
}

export default Browse;
