import React, { Fragment, useState } from "react";
import { ActionIcon, Grid, Col, Divider, Menu } from "@mantine/core";
import Icon from "@mdi/react";
import {
  mdiAccountCircleOutline,
  mdiBookPlusOutline,
  mdiHistory,
  mdiLogout,
} from "@mdi/js";
import { useHistory, NavLink } from "react-router-dom";

const UserMenu = ({ setIsAuthenticated }) => {
  const history = useHistory();
  const handleLogout = () => {
    setIsAuthenticated(false);
    history.push("/");
  };

  return (
    <Fragment>
      <NavLink to="/book/new" style={{ marginRight: "10px" }}>
        <ActionIcon>
          <Icon size={1} path={mdiBookPlusOutline} />
        </ActionIcon>
      </NavLink>
      <Menu
        control={
          <ActionIcon>
            <Icon size={1} path={mdiAccountCircleOutline} />
          </ActionIcon>
        }
        style={{ zIndex: 9 }}
      >
        <Menu.Label>Profile</Menu.Label>
        <Menu.Item icon={<Icon size={0.6} path={mdiHistory} />}>
          My Contributions
        </Menu.Item>
        <Divider />
        <Menu.Item
          icon={<Icon size={0.6} path={mdiLogout} />}
          onClick={() => handleLogout()}
        >
          Logout
        </Menu.Item>
      </Menu>
    </Fragment>
  );
};

const GuestMenu = ({ setIsAuthenticated }) => (
  <Fragment>
    <ActionIcon onClick={() => setIsAuthenticated(true)}>
      <Icon size={1} path={mdiAccountCircleOutline} />
    </ActionIcon>
  </Fragment>
);

function Template({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  return (
    <div
      style={{
        maxWidth: "980px",
        margin: "0 auto",
        padding: "60px 20px",
        zIndex: 0,
      }}
    >
      <div
        style={{
          background: "white",
          position: "fixed",
          padding: "10px 20px",
          zIndex: 1,
          width: "100%",
          top: 0,
          left: 0,
          boxSizing: "border-box",
          borderBottom: "1px solid #ddd",
        }}
      >
        <Grid>
          <Col span={4}>
            <NavLink to="/" style={{ display: "flex" }}>
              <svg
                width="32"
                height="32"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.5"
                  d="M2.97983 5.33931C3.01131 4.80783 3.25234 4.36556 3.65863 4.01345C4.15347 3.58447 4.76341 3.5 5.40188 3.5C5.61634 3.5 5.8308 3.5 6.04526 3.5L15.758 14.2483C15.7944 14.3128 15.8475 14.3802 15.9154 14.4542C17.7934 16.5128 19.6656 18.577 21.5367 20.6422L24.4211 23.8339C24.4329 23.8491 24.4457 23.8643 24.4565 23.8804C24.7743 24.3284 24.6198 24.6425 24.066 24.6805C23.9194 24.6909 23.7708 24.6843 23.6233 24.6843C20.5588 24.6843 17.4944 24.6843 14.4299 24.6843C11.3822 24.6843 8.33351 24.6853 5.28579 24.6834C3.71766 24.6824 2.93753 23.9269 2.93753 22.4122C2.93556 17.1173 2.93458 11.8205 2.93753 6.5247C2.93753 6.12989 2.95622 5.73412 2.97983 5.33931Z"
                  fill="#F2994A"
                />
                <path
                  d="M24.0656 3.50593C23.9191 3.4954 23.7705 3.5021 23.6229 3.5021C20.5584 3.5021 17.4939 3.5021 14.4294 3.5021C11.3806 3.5021 8.33278 3.50019 5.28497 3.5021C3.71582 3.50306 2.93665 4.26482 2.93665 5.79216C2.93567 11.1331 2.93469 15.9763 2.93764 21.3172C2.93764 21.5928 2.94747 21.8684 2.96026 22.144C3.1039 21.2119 3.92734 20.4971 4.92491 20.4971H9.00176C9.32739 20.4971 9.63631 20.3602 9.85077 20.1219L15.7595 14.0261C15.7959 13.961 15.849 13.893 15.9169 13.8184C17.7949 11.7427 19.6671 9.66125 21.5383 7.57885L24.4218 4.3586C24.4267 4.35286 24.4306 4.34712 24.4356 4.34233C24.7809 3.87437 24.6313 3.54517 24.0656 3.50593Z"
                  fill="#F2994A"
                />
              </svg>
            </NavLink>
          </Col>
          <Col span={8} style={{ display: "flex", justifyContent: "flex-end" }}>
            {isAuthenticated ? (
              <UserMenu setIsAuthenticated={setIsAuthenticated} />
            ) : (
              <GuestMenu setIsAuthenticated={setIsAuthenticated} />
            )}
          </Col>
        </Grid>
      </div>
      <div style={{ marginTop: "10px" }}>{children}</div>
    </div>
  );
}

export default Template;
