import { categories, authors, books } from "../../sandbox/dummyData";

import { ADD_CATEGORY } from "../../store/actions/bookActions";
export { addCategory } from "../../store/actions/bookActions";

const initialState = {
  categories: categories,
  authors: authors,
  books: books,
};

export function bookReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_CATEGORY:
      return {
        ...state,
        categories: [...state.categories, action.payload],
      };
    default:
      return state;
  }
}
