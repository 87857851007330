import React from "react";
import { useSelector } from "react-redux";
import { Avatar, Card, Divider, Text } from "@mantine/core";

const Reviewers = () => (
  <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
    {[1, 2, 3].map((item, index) => (
      <Avatar
        key={index}
        size="sm"
        radius="xl"
        src={
          item?.avatar
            ? item.avatar
            : "https://images.unsplash.com/photo-1508214751196-bcfd4ca60f91?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=255&q=80"
        }
      />
    ))}
    <Avatar color="grey" size="sm" radius="xl">
      +5
    </Avatar>
  </div>
);

function BookInfo({ name, slug, author, language, categories }) {
  const authors = useSelector((state) => state.authors);
  const authorName = authors.find((e) => e.id === author);

  return (
    <Card shadow="sm" padding="sm" component="a" href={`/book/view/${slug}`}>
      <Text weight={500} size="lg">
        {name}
      </Text>
      <Text size="sm" style={{ marginBottom: "10px" }}>
        {authorName?.name_lk}
      </Text>
      <Card.Section>
        <Divider />
      </Card.Section>
      <Reviewers />
    </Card>
  );
}

export default BookInfo;
