import React, { useState } from "react";
import {
  Grid,
  Col,
  Title,
  Textarea,
  InputWrapper,
  SegmentedControl,
  Text,
  Group,
  Badge,
  Button,
} from "@mantine/core";
import { useSelector } from "react-redux";
import Template from "../../templates/default";

function ViewBook({ match }) {
  const books = useSelector((state) => state.books);
  const book = books.find((e) => e.slug === match.params.slug);
  const { name_lk, name_en, author, publisher, language, categories } = book;

  const [rating, setRating] = useState(3);

  return (
    <Template>
      <div style={{ margin: "20px 0 30px" }}>
        <Group withGutter>
          <Title order={3}>{name_lk ? name_lk : name_en}</Title>
          {language && <Badge>{language}</Badge>}
        </Group>
        <Text>{publisher}</Text>
      </div>

      <Title order={4}>Add comment</Title>
      <form style={{ marginTop: "10px" }}>
        <Grid>
          <Col span={12} md={6}>
            <InputWrapper required label="Rating">
              <SegmentedControl
                value={rating}
                onChange={setRating}
                data={[
                  { label: "1", value: "1" },
                  { label: "2", value: "2" },
                  { label: "3", value: "3" },
                  { label: "4", value: "4" },
                  { label: "5", value: "5" },
                ]}
              />
            </InputWrapper>
          </Col>
          <Col span={12}>
            <InputWrapper label="Review" required>
              <Textarea placeholder="Review" />
            </InputWrapper>
          </Col>
          <Col span={12}>
            <InputWrapper
              label="Internal remarks"
              description="For internal notes only"
            >
              <Textarea placeholder="Internal remarks" />
            </InputWrapper>
          </Col>
          <Col span={12}>
            <Button onClick={() => {}}>Add comment</Button>
          </Col>
        </Grid>
      </form>
    </Template>
  );
}

export default ViewBook;
