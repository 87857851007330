import React, { useState } from "react";
import { Input, InputWrapper, Modal, Group, Button } from "@mantine/core";
import { useDispatch } from "react-redux";
import { addAuthor } from "../../store/actions/bookActions";

export const AddAuthorModal = ({ opened, setOpened }) => {
  const dispatch = useDispatch();
  const [inputValues, setInputValues] = useState({
    name: undefined,
    slug: undefined,
  });

  return (
    <Modal opened={opened} onClose={() => setOpened(false)} title="Add author">
      <Group grow direction="column" align="flex-start">
        <InputWrapper required label="Category name">
          <Input
            placeholder="Author's name"
            onChange={(e) =>
              setInputValues((prev) => ({ ...prev, name: e.target.value }))
            }
          />
        </InputWrapper>
        <InputWrapper
          required
          label="Author page slug"
          description="No spaces, english only"
        >
          <Input
            placeholder="Category slug"
            onChange={(e) =>
              setInputValues((prev) => ({ ...prev, slug: e.target.value }))
            }
          />
        </InputWrapper>
        <Button
          disabled={!(inputValues.slug && inputValues.name)}
          onClick={() => {
            dispatch(
              addAuthor({ label: inputValues.name, value: inputValues.slug })
            );
            setOpened(false);
          }}
        >
          Create author
        </Button>
      </Group>
    </Modal>
  );
};
