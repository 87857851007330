import React, { useState } from "react";
import {
  Grid,
  Col,
  Title,
  Textarea,
  Input,
  InputWrapper,
  SegmentedControl,
  MultiSelect,
  ActionIcon,
  Button,
} from "@mantine/core";
import Icon from "@mdi/react";
import { mdiPlus } from "@mdi/js";
import { useSelector } from "react-redux";
import Template from "../../templates/default";
import { AddCategoryModal } from "../../../components/AddCategoryModal";
import { AddAuthorModal } from "../../../components/AddAuthorModal";

function AddBook() {
  const [language, setLanguage] = useState("sinhala");
  const [addCategoryDialogOpen, setAddCategoryDialogOpen] = useState(false);
  const [addAuthorDialogOpen, setAddAuthorDialogOpen] = useState(false);

  const unprocessedCategories = useSelector((state) => state.categories);
  const categories = [];

  unprocessedCategories.map((category) =>
    categories.push({
      label: category.name_si,
      value: category.slug,
    })
  );

  return (
    <Template>
      <Title order={3}>Add new book</Title>
      <AddCategoryModal
        opened={addCategoryDialogOpen}
        setOpened={setAddCategoryDialogOpen}
      />
      <AddAuthorModal
        opened={addAuthorDialogOpen}
        setOpened={setAddAuthorDialogOpen}
      />
      <form style={{ marginTop: "20px" }}>
        <Grid>
          <Col span={12} md={6}>
            <InputWrapper required label="Book language">
              <SegmentedControl
                value={language}
                onChange={setLanguage}
                data={[
                  { label: "Sinhala", value: "sinhala" },
                  { label: "English", value: "english" },
                ]}
              />
            </InputWrapper>
          </Col>
        </Grid>
        <Grid>
          <Col span={12} md={6}>
            <InputWrapper required label="Book name">
              <Input placeholder="Book name" />
            </InputWrapper>
          </Col>
          <Col span={12} md={6}>
            {language === "sinhala" && (
              <InputWrapper
                required={language === "sinhala"}
                label="Book name in English letters"
              >
                <Input placeholder="Book name English letters" />
              </InputWrapper>
            )}
          </Col>
          <Col span={12} md={6}>
            <div style={{ display: "flex", alignItems: "flex-end" }}>
              <div style={{ flex: "1" }}>
                <MultiSelect
                  data={categories}
                  label="Author"
                  placeholder="Author"
                />
              </div>
              <div style={{ margin: "0 0  4px 8px" }}>
                <ActionIcon
                  variant="light"
                  onClick={() => setAddAuthorDialogOpen(true)}
                >
                  <Icon path={mdiPlus} size={1} />
                </ActionIcon>
              </div>
            </div>
          </Col>
          <Col span={12} md={6}>
            <InputWrapper label="Publisher">
              <Input placeholder="Publisher" />
            </InputWrapper>
          </Col>
          <Col span={12} md={6}>
            <InputWrapper label="Cover image">
              <Input type="cover" name="cover" accept="image/png, image/jpeg" />
            </InputWrapper>
          </Col>
          <Col span={12} md={6}>
            <div style={{ display: "flex", alignItems: "flex-end" }}>
              <div style={{ flex: "1" }}>
                <MultiSelect
                  data={categories}
                  label="Categories"
                  placeholder="Categories"
                />
              </div>
              <div style={{ margin: "0 0  4px 8px" }}>
                <ActionIcon
                  variant="light"
                  onClick={() => setAddCategoryDialogOpen(true)}
                >
                  <Icon path={mdiPlus} size={1} />
                </ActionIcon>
              </div>
            </div>
          </Col>
          <Col span={12}>
            <InputWrapper label="Other details">
              <Textarea placeholder="Other details" />
            </InputWrapper>
          </Col>
          <Col span={12}>
            <Button onClick={() => {}}>Create book</Button>
          </Col>
        </Grid>
      </form>
    </Template>
  );
}

export default AddBook;
