/**
 * Testing purposes only
 */
export const categories = [
  { id: 1, name_si: "අධ්‍යාපනික", slug: "education" },
  { id: 2, name_si: "පරිවර්තන", slug: "translation" },
  { id: 3, name_si: "නවකතා", slug: "navel" },
  { id: 4, name_si: "සම්මාන", slug: "award-wining" },
  { id: 5, name_si: "කාව්‍යය", slug: "poetic" },
  { id: 6, name_si: "කෙටිකතා", slug: "short-stories" },
  { id: 7, name_si: "ළමා", slug: "childrens" },
  { id: 8, name_si: "යොවුන් නවකතා", slug: "teen-novel" },
  { id: 9, name_si: "ඉතිහාසය හා පුරාවිද්‍යාව", slug: "historic" },
  { id: 10, name_si: "චරිතාපදාන", slug: "biography" },
  { id: 11, name_si: "රහස් පරීක්ෂණ කතා", slug: "detective" },
  { id: 12, name_si: "ත්‍රාස්‍යජනක", slug: "thriller" },
  { id: 13, name_si: "විවිධ", slug: "other" },
  { id: 14, name_si: "ජ්‍යෝතිෂ්‍යය", slug: "horoscope" },
  { id: 15, name_si: "සඟරා", slug: "magazine" },
  { id: 16, name_si: "දේශපාලන", slug: "politics" },
  { id: 17, name_si: "ආගමික", slug: "religious" },
  { id: 18, name_si: "ආදර කතා", slug: "love" },
];

export const authors = [
  {
    id: 1,
    name_lk: "මාර්ටින් වික්‍රමසිංහ",
    name_en: "Martin Wickramasinghe",
    name_alt: [],
    slug: "martin-wikramasinghe",
    active: 1,
  },
  {
    id: 2,
    name_lk: "එදිරිවීර සරච්චන්ද්‍ර",
    name_en: "Ediriweera Sarachchandra",
    name_alt: [],
    slug: "ediriweera-sarachchandra",
    active: 1,
  },
];

export const books = [
  {
    id: 1,
    name_lk: "මඩොල් දූව",
    name_en: "Madol Doova",
    slug: "madol-doova",
    name_alt: [],
    language: "sinhala",
    publisher: "",
    cover_image: "",
    categories: [],
    remarks: "",
    active: 1,
    author: 1,
  },
  {
    id: 2,
    name_lk: "අපේ ගම",
    name_en: "Ape Gama",
    slug: "ape-gama",
    name_alt: [],
    language: "sinhala",
    publisher: "",
    cover_image: "",
    categories: [],
    remarks: "",
    active: 1,
    author: 1,
  },
  {
    id: 3,
    name_lk: "මළගිය ඇත්තෝ",
    name_en: "Mala Giya Aththo",
    slug: "mala-giya-aththo",
    name_alt: [],
    language: "sinhala",
    publisher: "",
    cover_image: "",
    categories: [],
    remarks: "",
    active: 1,
    author: 2,
  },
  {
    id: 4,
    name_lk: "සිංහබාහු",
    name_en: "Sinhabahu",
    slug: "sinhabahu",
    name_alt: [],
    language: "sinhala",
    publisher: "",
    cover_image: "",
    categories: [],
    remarks: "",
    active: 1,
    author: 2,
  },
];
