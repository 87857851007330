import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Route } from "react-router-dom";
import configureStore from "./store/configureStore";
import Browse from "./layouts/browse";
import AddBook from "./layouts/book/add";
import ViewBook from "./layouts/book/view";
import "./global.scss";

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <BrowserRouter>
        <Route exact path="/" component={Browse} />
        <Route path="/book/new" component={AddBook} />
        <Route path="/book/view/:slug" component={ViewBook} />
      </BrowserRouter>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);
