export const ADD_BOOK = "ADD_BOOK";
export const ADD_AUTHOR = "ADD_AUTHOR";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const ADD_COMMENT = "ADD_COMMENT";

/**
 * TODO: Dummy function to add a category to redux store
 */
export function addCategory(categoryData) {
  const payload = {
    name_si: categoryData.label,
    slug: categoryData.value,
  };

  return {
    type: ADD_CATEGORY,
    payload: payload,
  };
}

/**
 * TODO: Dummy function to add an author to redux store
 */
export function addAuthor(author) {
  return {
    type: ADD_AUTHOR,
    payload: author,
  };
}
